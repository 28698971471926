import { useEffect, useState, useContext } from 'react';
import { Helmet } from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useLocation,
  useHistory,
} from "react-router-dom";
import qs from 'qs';
import { FaSpinner } from 'react-icons/fa';

import AppContext from '../../AppContext';
import Shopify from './Shopify';
import PrivateRoute from '../../PrivateRoute';
import api from '../../api';

import './index.scss';


const Integrations = ({mixpanel}) => {
  const { apiBaseUrl } = useContext(AppContext);
  const { path } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const [isProcessingToken, setIsProcessingToken] = useState(true);
  const [installCodeErrorMessage, setInstallCodeErrorMessage] = useState();

  useEffect(() => {
    (async () => {
      const params = qs.parse(location.search, { ignoreQueryPrefix: true });
      const {shop} = params;
      try {
        setIsProcessingToken(true);
        setInstallCodeErrorMessage(null);
        await api.get('/oauth/shopify/access_token', {
          params,
        });

        setIsProcessingToken(false);
      } catch (e) {
        switch (e?.response?.status) {
        case 400:
          // Don't show an error message for an expired/used code, or for request integrity or old request
          // Instead, if the web pixel can't connect, the OAuth flow will be restarted from there.
          setIsProcessingToken(false);
          break;
        case 404:
          // We maintain the isProcessingToken state to give time for the below redirect to happen
          window.location.href = `${apiBaseUrl}/oauth/shopify/install?shop=${shop}`;
          break;
        default:
          setInstallCodeErrorMessage('There was a problem processing the installation. Please return to your Shopify account and click to open the the Zuko Checkout Analytics app again.');
          setIsProcessingToken(false);
        }
      }
    })();
  }, [location.search, history, apiBaseUrl]);

  return (
    <Container fluid className="integrations page">
      <Helmet titleTemplate="%s | Zuko" defaultTitle="Zuko" defer={false}>
        <title>Integrations</title>
      </Helmet>
      {isProcessingToken && <>
        <div className="nav-wrapper">
          <Navbar expand="lg" variant="dark" id="main-nav">
            <Container className="mw-100">
              <Navbar.Brand><div className="zuko-logo" role="img" alt="logo"></div></Navbar.Brand>
              <Navbar.Toggle />
            </Container>
          </Navbar>
        </div>
        <div className="main-content">
          <Col className="center-column justify-content-md-center">
            <Row className="alert-row g-0 mt-3">
              <Alert variant="info" closeVariant="white">
                <div className="alert-svg-icon my-auto me-3"><FaSpinner size="16px" className="spinning-icon" title="Connecting to Shopify Checkout.."/></div>
                <p className="alert-text m-0">Loading integrations...</p>
              </Alert>
            </Row>
          </Col>
        </div>
      </>}
      {!isProcessingToken &&
      <Switch>
        <Route exact path={path}>
          <Redirect to={`${path}/shopify`} />
        </Route>
        <PrivateRoute component={Shopify} path={`${path}/shopify`} mixpanel={mixpanel} installCodeErrorMessage={installCodeErrorMessage} />
      </Switch>}
    </Container>
  );
};

export default Integrations;
