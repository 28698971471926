import React, { useState, useEffect, useCallback, useContext }  from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { VscCheck, VscWarning } from "react-icons/vsc";
import { HiExternalLink } from 'react-icons/hi';
import { usePrevious } from '../../hooks';
import { suggestEmailMatch } from '../../helpers/email-matcher';
import ReCAPTCHA from 'react-google-recaptcha';
import qs from 'qs';
import DOMPurify from 'dompurify';
import * as Sentry from '@sentry/react';
import { splitName } from '../../utils';
import AppContext from '../../AppContext';

import api from '../../api';

const ValidInputWrapper = ({children, forInput}) => (
  <div className="d-flex align-items-center justify-content-center">
    {children}
    <div className="valid-input-icon" data-testid={`valid-${forInput || 'input'}-icon`}><VscCheck size="30px"/></div>
  </div>
);

const takenEmailErrorMsg = 'An account already exists for this email address. Please <a href="/login">login</a> or try another email.';
const invalidEmailErrorMsg = 'This email is invalid. Please try a different email, or contact <a href="mailto:support@zuko.io">Zuko support</a>.';
const takenOrgErrorMsg = 'An organisation with this name already exists. A current Zuko user at the organisation needs to add you to the account. Alternatively, try a different organisation name, or contact <a href="mailto:support@zuko.io">Zuko support</a>.';
const mainErrorMsg = "We're sorry, something has gone wrong creating your account. Please try again or contact <a href='mailto:support@zuko.io'>Zuko support</a>.";

const SignUpForm = ({
  mixpanel,
  product = 'analytics',
  autoLoginTo,
  setShowCreateAccountSuccess, // Used when auto login is not in place
  showCreateAccountSuccess,
  showOrgInput,
  providedOrgName,
}) => {
  const { setUser } = useContext(AppContext);
  const history = useHistory();
  const location = useLocation();
  const { utm_source } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [nameIsValid, setNameIsValid] = useState();
  const [nameErrorMsg, setNameErrorMsg] = useState();
  const [emailIsValid, setEmailIsValid] = useState();
  const [emailErrorMsg, setEmailErrorMsg] = useState();
  const [orgNameIsValid, setOrgNameIsValid] = useState();
  const [orgNameErrorMsg, setOrgNameErrorMsg] = useState();
  const [emailHint, setEmailHint] = useState();
  const [showEmailHint, setShowEmailHint] = useState();
  const [formError, setFormError] = useState();
  const [isSubmitting, setIsSubmitting] = useState();

  const [name, setName] = useState();
  const [orgName, setOrgName] = useState();
  const [email, setEmail] = useState();
  const [termsSelected, setTermsSelected] = useState();
  const [submitAttempted, setSubmitAttempted] = useState(false);

  const prevName = usePrevious(name);
  const prevEmail = usePrevious(email);
  const prevOrgName = usePrevious(orgName);

  const recaptchaRef = React.createRef();

  const termsIsInvalid = ((submitAttempted && termsSelected === undefined) || termsSelected === false);

  const slug = '2aaa7631fdfee2eb';
  const orgRequiredMsg = 'We need this so you can add forms and share account access with your colleagues.';

  const handleCreateAccount = async (e) => {
    e.preventDefault(); // Prevent the form from resetting inputs
    window.zukoTrackingInstance?.setAttribute('usedOAuth', false);

    setFormError(null);

    // Native form validation
    if (!e.currentTarget.checkValidity()) {
      window.zukoTrackingInstance?.trackEvent({type:'Attempted submit but form invalid'});

      // Validate each input to add appropriate error message if submit attempted with missing values
      if (!name) validateName();
      if (!email) validateEmail();
      if (!orgName) validateOrgName();
      // NB terms error message will automatically display if not selected

      // Send a specific custom event for missing terms after submit attempt
      if (!termsSelected) window.zukoTrackingInstance?.trackEvent({type: 'Attempted submit but terms not checked'});

      return;
    }

    // reCaptcha - reset recaptcha on each submission
    if (recaptchaRef.current.getValue()) {
      recaptchaRef.current.reset();
    }
    try {
      const token = await recaptchaRef.current.executeAsync(); // NB. Only one execute request can be in progress.
      const {data: {success}} = await api.post('/recaptcha/verify', {token});
      if (!success) throw new Error('reCaptcha failed');
    } catch (e) {
      setFormError("Unfortunately, we were unable to make sure that you are human. Please try again or " +
              "contact <a href='mailto:support@zuko.io'>Zuko support</a>.");
      return;
    }

    // NB. Currently the only reason why a double click doesn't proceed past reCaptcha is because executeAsync is throttled.
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      const { data: {user} } = await api.post('/users', {
        user: {
          name: DOMPurify.sanitize(name),
          email,
          role: 'admin',
        },
        companyName: orgName || providedOrgName,
      });

      window.zukoTrackingInstance?.trackEvent(window.Zuko?.COMPLETION_EVENT);

      // Push event to Gogle data layer
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({event: 'analytics_user_sign_up_completed'});

      const profile = {
        $email: email
      };

      if (name) {
        const nameMatch = splitName(name);
        const firstName = nameMatch[1];
        const lastName = nameMatch[2];
        if (firstName) profile.$first_name = firstName;
        if (lastName) profile.$last_name = lastName;
      }

      mixpanel.people.set(profile);
      mixpanel.identify(email);
      mixpanel.track('Completed Sign Up', { page: 'Sign Up', product, 'Organisation Name': orgName,
        'Organisation Contract Type': 'trial',
      });

      if (setShowCreateAccountSuccess) setShowCreateAccountSuccess(true);
      if (autoLoginTo) {
        setUser(user);
        mixpanel.track('Login', { product,
          'First Organisation Name': user.organisations[0].name,
          'First Organisation Contract Type': 'trial',
        });
        history.push(autoLoginTo, {signUpSuccess: true});
      }
    } catch (e) {
      if (e.response && e.response.status === 400 && e.response.data.errors && Object.keys(e.response.data.errors)?.length > 0) {
        const { name, email, companyName } = e.response.data.errors;

        if (name) {
          setNameIsValid(false);
          setNameErrorMsg(name.map((e) => `Name ${e}`).join(',') );
        }

        if (email) {
          setEmailIsValid(false);
          setEmailErrorMsg(email.map((e) => e === 'is invalid' ? invalidEmailErrorMsg : e === 'has already been taken' ? takenEmailErrorMsg : `Email ${e}`).join(',') );
        }

        if (companyName) {
          setOrgNameIsValid(false);
          setOrgNameErrorMsg(companyName.map((e) => e === 'Validation failed: Name has already been taken' ? takenOrgErrorMsg : `Organisation: ${e}`).join(',') );
        }
      } else {
        Sentry.captureException(e);
        setFormError(mainErrorMsg);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const validateName = useCallback(() => {
    if (!name) {
      setNameIsValid(false);
      setNameErrorMsg("Please enter your name, we'll need it to say hello.");
      return;
    }

    if (name?.match(/(:\/\/)|(www\.)/)) {
      setNameIsValid(false);
      setNameErrorMsg('Please enter your name' );
      return;
    }

    setNameIsValid(true);
  },[name]);

  const validateEmail = useCallback(async (emailElement) => {
    if (!email) {
      setEmailIsValid(false);
      setEmailErrorMsg('We need your email to send you the password link and set up your login details.' );
      return;
    }

    if (!email.includes('@')) {
      setEmailIsValid(false);
      setEmailErrorMsg('This email address is incomplete - it needs an @.' );
      return;
    }

    if (email.match(/^[\w+\-.]+@$/i)) {
      setEmailIsValid(false);
      setEmailErrorMsg('This email does not have a domain - you need to use standard email format: email@domain.com.' );
      return;
    }

    if (email.match(/^[\w+\-.]+@([a-z\d-]+|[a-z\d-.]+\.)$/i)) {
      setEmailIsValid(false);
      setEmailErrorMsg('This email looks incomplete - you need to use standard email format: email@domain.com.' );
      return;
    }

    const suggestedMatch = suggestEmailMatch(email);
    if (suggestedMatch) {
      setEmailHint(`Hint: Did you mean ${suggestedMatch}?`);
      setShowEmailHint(true);
    }

    let emailContainsErrors;
    try {
      const { data: { errors } } = await api.post('/users/validate', {
        user: { email }
      });

      if (errors?.email?.length) {
        emailContainsErrors = true;
        setEmailIsValid(false);
        setEmailErrorMsg(errors.email.map((e) => e === 'invalid_email' ? invalidEmailErrorMsg : e === 'taken_email' ? takenEmailErrorMsg : e).join(',') );
      }
      if (emailContainsErrors) return;
    } catch (e) {
      setFormError("We're sorry, something went wrong validating your email address. Please try again or contact <a href='mailto:support@zuko.io'>Zuko support</a>.");
      return;
    }

    if (emailElement && !emailElement?.checkValidity()) {
      setEmailIsValid(false);
      setEmailErrorMsg(invalidEmailErrorMsg);
      return;
    }

    setEmailErrorMsg(null);
    setEmailIsValid(true);
  },[email]);

  const validateOrgName = useCallback(async () => {
    if (!orgName) {
      setOrgNameIsValid(false);
      setOrgNameErrorMsg(orgRequiredMsg);
      return;
    }

    let orgContainsErrors;
    try {
      const { data: { errors } } = await api.post('/users/validate', {
        user: { orgName }
      });

      if (errors?.orgName?.length) {
        orgContainsErrors = true;
        setOrgNameIsValid(false);
        setOrgNameErrorMsg(errors.orgName.map((e) => e === 'taken_name' ? takenOrgErrorMsg : e).join(',') );
      }
      if (orgContainsErrors) return;
    } catch (e) {
      setFormError("We're sorry, something went wrong validating your organisation. Please try again or contact <a href='mailto:support@zuko.io'>Zuko support</a>.");
      return;
    }

    setOrgNameErrorMsg(null);
    setOrgNameIsValid(true);
  },[orgName, orgRequiredMsg]);

  // Validate name after expected autofil or after removing the value whilst still in the input
  useEffect(() => {
    if (((!prevName || prevName?.length < 3) && name?.length > 3)) validateName();
    if (prevName?.length && !name?.length) validateName();
  }, [name, prevName, validateName]);

  // Validate email after expected autofil or after removing the value whilst still in the input
  useEffect(() => {
    if (((!prevEmail || prevEmail?.length < 6) && email?.length > 6)) validateEmail();
    if (prevEmail?.length && !email?.length) validateEmail();
  }, [email, prevEmail, validateEmail]);

  // Validate org name after expected autofil or after removing the value whilst still in the input
  useEffect(() => {
    if (((!prevOrgName || prevOrgName?.length < 4) && orgName?.length > 4)) validateOrgName();
    if (prevOrgName?.length && !orgName?.length) validateOrgName();
  }, [orgName, prevOrgName, validateOrgName]);

  // Remove email hint after a short time
  useEffect(() => {
    if (showEmailHint) setTimeout(() => {setShowEmailHint(null);}, 8000);
  },[showEmailHint]);

  // Zuko activity tracking
  useEffect(() => {
    const zukoReplayScript = document.createElement('script');
    zukoReplayScript.src = 'https://assets.zuko.io/replay/v1/record.min.js';
    document.body.appendChild(zukoReplayScript);

    const zukoScript = document.createElement('script');
    zukoScript.src = 'https://assets.zuko.io/js/v2/client.min.js';
    document.body.appendChild(zukoScript);

    // Only initiate tracking when Zuko has loaded
    zukoScript.addEventListener('load', () => {
      if (window.Zuko?.trackMedium) window.Zuko?.trackMedium();
      let source;
      if (utm_source) source = utm_source;
      window.zukoTrackingInstance = window.Zuko?.trackForm({target:document.getElementById('signup-form'),slug})
        .setAttribute('trafficSource', source)
        .setAttribute('product', 'analytics')
        .trackEvent(window.Zuko?.FORM_VIEW_EVENT);
    });

    zukoReplayScript.addEventListener('load', () => {
      window.ZukoReplay?.recordForm({slug});
    });
  }, [utm_source]);

  // Zuko custom events tracking
  useEffect(() => {
    if (nameErrorMsg) window.zukoTrackingInstance?.trackEvent({type: `Name: ${nameErrorMsg}`});
  }, [name, nameErrorMsg]);

  useEffect(() => {
    if (emailErrorMsg) window.zukoTrackingInstance?.trackEvent({type: `Email: ${emailErrorMsg}`});
  }, [email, emailErrorMsg]);

  useEffect(() => {
    if (orgNameErrorMsg) window.zukoTrackingInstance?.trackEvent({type: `Organisation: ${orgNameErrorMsg}`});
  }, [orgName, orgNameErrorMsg]);

  useEffect(() => {
    if (termsSelected === false) window.zukoTrackingInstance?.trackEvent({type: 'Terms: Please accept the terms so you can use the Zuko platform.'});
  }, [termsSelected]);

  useEffect(() => {
    if (formError) window.zukoTrackingInstance?.trackEvent({type: formError});
  }, [formError]);

  return ( <>
    {formError &&
      <Row className="alert-row g-0 mt-0">
        <Alert variant="danger" dismissible closeVariant="white" onClose={() => setFormError(null)}>
          <div className="alert-svg-icon my-auto"><VscWarning size="100%"/></div>
          <p className="alert-text m-0" dangerouslySetInnerHTML={{ __html: formError }} />
        </Alert>
      </Row>}
    <Row className="g-0 justify-content-center" id="login-container">
      <Col className="col-auto p-0" id="login-container-col">
        <Form noValidate onSubmit={handleCreateAccount} className="login-form" id="signup-form">
          <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} badge="bottomleft" />
          {!showCreateAccountSuccess && <>
            <FormGroup controlId="name" className={`form-group ${nameIsValid === true ? 'valid' : nameIsValid === false ? 'invalid' : ''}`}>
              <Form.Label>Name</Form.Label>
              {nameErrorMsg && <Form.Control.Feedback type="invalid" data-testid="name-invalid-feedback">{nameErrorMsg}</Form.Control.Feedback>}
              <ValidInputWrapper>
                <Form.Control type="text" name="name" autoComplete="name" value={name || ''} maxLength={255} pattern="^(?!.*(www\.|:\/\/)).*$"
                  className={((submitAttempted && !name) || (nameIsValid === false)) ? 'invalid-input' : nameIsValid ? 'is-valid' : ''}
                  onChange={({target: {value}}) => {
                    setName(value);
                    if (nameIsValid === false) {
                      setNameIsValid(null);
                      setNameErrorMsg(null);
                    }
                  }} required
                  onBlur={() => validateName()}
                  tabIndex={0}
                />
              </ValidInputWrapper>
            </FormGroup>

            <FormGroup controlId="email" className={`form-group ${emailIsValid === true ? 'valid' : emailIsValid === false ? 'invalid' : ''}`}>
              <Form.Label>Email</Form.Label>
              {emailErrorMsg && <Form.Control.Feedback type="invalid" data-testid="email-invalid-feedback" className="feedback-row" dangerouslySetInnerHTML={{ __html: emailErrorMsg }}/>}
              <ValidInputWrapper forInput="email">
                <OverlayTrigger show={showEmailHint ? true : false}
                  onExited={() => setEmailHint(null)} // Safely remove the email hint message once the tooltip is no longer visible
                  overlay={
                    <Tooltip id="hint-tooltip">{emailHint}</Tooltip>}>
                  <Form.Control type="email" name="email" autoComplete="email" value={email || ''} maxLength={255}
                    className={((submitAttempted && !email) || emailIsValid === false) ? 'invalid-input' : emailIsValid ? 'is-valid' : ''}
                    onChange={({target: {value}}) => {
                      setEmail(value);
                      if (emailIsValid === false) {
                        setEmailIsValid(null);
                        setEmailErrorMsg(null);
                      }
                      if (showEmailHint) setShowEmailHint(false);
                    }} required
                    onBlur={(e) => validateEmail(e.target)}
                    tabIndex={0}
                  />
                </OverlayTrigger>
              </ValidInputWrapper>
            </FormGroup>

            {showOrgInput &&
              <FormGroup controlId="organisation" className={`form-group ${orgNameIsValid === true ? 'valid' : orgNameIsValid === false ? 'invalid' : ''}`}>
                <Form.Label>Organisation</Form.Label>
                {!orgNameErrorMsg && <Form.Control.Feedback className="feedback-row feedback-info">{orgRequiredMsg}</Form.Control.Feedback>}
                {orgNameErrorMsg && <Form.Control.Feedback type="invalid" data-testid="org-invalid-feedback" className="feedback-row" dangerouslySetInnerHTML={{ __html: orgNameErrorMsg }}/>}
                <ValidInputWrapper>
                  <Form.Control type="text" name="company" autoComplete="organization" value={orgName || ''} maxLength={255}
                    className={((submitAttempted && !orgName) || (orgNameIsValid === false)) ? 'invalid-input' : orgNameIsValid ? 'is-valid' : ''}
                    onChange={({target: {value}}) => {
                      setOrgName(value);
                      if (orgNameIsValid === false) {
                        setOrgNameIsValid(null);
                        setOrgNameErrorMsg(null);
                      }
                    }} required
                    onBlur={() => validateOrgName()}
                    tabIndex={0}
                  />
                </ValidInputWrapper>
              </FormGroup>}

            <fieldset className={`checkbox-fieldset single-checkbox pt-3 ${termsIsInvalid ? 'displaying-error' : ''}`}>
              <FormGroup controlId="termsandpolicycheckbox" className={`form-group d-inline-flex align-items-center mb-0 checkbox-item ${termsSelected ? 'valid' : termsSelected === false ? 'invalid' : ''}`}>
                <Form.Check type="checkbox" name="terms-and-policy-checkbox" isInvalid={termsIsInvalid} data-testid="terms-checkbox" checked={termsSelected || false} required
                  className={`d-flex mt-0 ps-0`}
                  onChange={() => {
                    setTermsSelected(!termsSelected);
                  }}
                  tabIndex={0} label={<>I accept the&nbsp;
                    <a target="_blank" rel="noopener noreferrer" href="https://www.zuko.io/terms-and-conditions">Terms & Conditions<HiExternalLink className="align-top" /></a>&nbsp;and&nbsp;
                    <a target="_blank" rel="noopener noreferrer" href="https://www.zuko.io/privacy-policy">Privacy Policy<HiExternalLink className="align-top" /></a></>}/>
              </FormGroup>
              <Form.Control.Feedback type="invalid" data-testid="terms-invalid-feedback" className={`pb-0 feedback-row checkbox-feedback`}>
                {termsIsInvalid && 'Please accept so you can use the Zuko platform.'}
              </Form.Control.Feedback>
            </fieldset>

            <Row className="g-0 login-btn justify-content-center pt-3">
              <Button variant="primary" className="submit-btn" type="submit" onClick={() => setSubmitAttempted(true)} disabled={isSubmitting}>Create Account</Button>
            </Row>
          </>}
        </Form>
      </Col>
    </Row>
  </>);
};

export default SignUpForm;
