import React, { useState, useEffect, useMemo, useContext }  from 'react';
import { useLocation, Link, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import qs from 'qs';
import { FaSpinner } from 'react-icons/fa';
import { VscWarning } from 'react-icons/vsc';

import AppContext from '../../../../AppContext';
import SignUpForm from '../../../SignUp/SignUpForm';
import GoogleOAuthLogin from '../../../SignUp/GoogleOAuthLogin';
import SocialProof from '../../../SignUp/SocialProof';
import api from '../../../../api';

import '../../../SignUp/SignUp.scss';

const ShopifySignUp = ({mixpanel}) => {
  const { currentUser } = useContext(AppContext);
  const location = useLocation();
  const [googleSignUpLoading, setGoogleSignUpLoading] = useState();
  const [codeError, setCodeError] = useState();
  const params = useMemo(() => qs.parse(location.search, { ignoreQueryPrefix: true }), [location.search]);
  const { shop } = params;
  const [forwardingSearchParams, setForwardingSearchParams] = useState((new URLSearchParams(location.search)).toString());
  const searchString = `?${forwardingSearchParams.toString()}`;
  const autoLoginTo = `/integrations/shopify${searchString}`;

  useEffect(() => {
    (async () => {
      try {
        setCodeError(null);
        await api.get('/oauth/shopify/access_token', {
          params,
        });

        const newParams = new URLSearchParams(params);
        newParams.delete('code');
        setForwardingSearchParams(newParams.toString());
      } catch (e) {
        setCodeError(true);
      }
    })();
  }, [params]);

  return (<>{currentUser ? <Redirect to={autoLoginTo} /> :
    <Container fluid className="page login-background" id="sign-up-page">
      <Helmet titleTemplate="%s | Zuko" defaultTitle="Zuko" defer={false}>
        <title>Shopify Sign Up</title>
      </Helmet>
      <Col className="center-column justify-content-center d-flex flex-column">
        <Row className="g-0 login-logo justify-content-center">
          <div className="logo-wrapper">
            <div className="zuko-full-colour-logo" role="img" alt="Zuko logo"></div>
          </div>
        </Row>
        <Row className="g-0 login-title justify-content-center">
          <h1>Form Analytics for Checkout</h1>
        </Row>
        <Row className="g-0 signup-info-title justify-content-center text-center">
          <h3>Measure your first 1,000 checkout visits with access to all reporting features.</h3>
        </Row>
        <Row className="g-0 justify-content-center signup-info-title">
          <p className="mb-2 bold-msg text-center">No credit card required.</p>
        </Row>
        {codeError &&
          <Row className="alert-row g-0 mt-0">
            <Alert variant="danger" dismissible closeVariant="white" onClose={() => setCodeError(null)}>
              <div className="alert-svg-icon my-auto"><VscWarning size="100%"/></div>
              <p className="alert-text m-0">There was a problem starting the installation. In your Shopify Apps settings, please uninstall and re-install the Zuko Checkout Analytics app.</p>
            </Alert>
          </Row>}
        {googleSignUpLoading &&
          <Row className="g-0 d-flex justify-content-center text-center mt-3">
            <FaSpinner size="18px" className="spinning-icon mb-2" title="Signing up.."/>
            <p>Completing sign up, please wait to be logged in...</p>
          </Row>}
        {!googleSignUpLoading && <>
          <SignUpForm
            mixpanel={mixpanel}
            autoLoginTo={autoLoginTo}
            providedOrgName={shop}
            showOrgInput={false}
          />
          <GoogleOAuthLogin
            setGoogleSignUpLoading={setGoogleSignUpLoading}
            googleSignUpLoading={googleSignUpLoading}
            autoLoginTo={autoLoginTo}
          />
          <Row className="g-0 justify-content-center login-link mt-5">
            <p className="mb-0 text-center">Already have an account? <Link to={{
              pathname: '/integrations/shopify/login',
              state: {from: {path: '/integrations/shopify', search: searchString }}
            }}>Log in</Link></p>
          </Row>
          <SocialProof />
        </>}
      </Col>
    </Container>}
  </>);
};

export default ShopifySignUp;
