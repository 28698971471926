import React from 'react';
import { Link } from 'react-router-dom';
import Popover from 'react-bootstrap/Popover';
import Alert from 'react-bootstrap/Alert';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { HiExternalLink } from 'react-icons/hi';
import { VscInfo } from 'react-icons/vsc';

import './FormTrackingCodeWizard.scss';

const CheckDataOnly = ({
  form,
}) => {
  return (
    <div className="d-flex flex-column col" id="tracking-code-wizard">
      <div className="pb-2 pt-0 d-flex flex-column px-4 flex-grow-1">
        <div className="mt-0 d-flex flex-column">
          <Alert variant="outline-info" className="mb-3 mx-auto w-fit-content">
            <div className="alert-svg-icon my-auto"><VscInfo size="20px"/></div>
            <p className="alert-text m-0 ps-3 pe-0 py-0">Please make sure the Zuko Checkout Analytics app is installed on your checkout.</p>
          </Alert>
          <h4 className="">Steps to check the app is installed:</h4>
          <ol className="further-info-content">
            <li className="mb-1">Go to your Shopify admin &gt; Settings</li>
            <li className="mb-1">Click into <i>Apps and sales channels</i> and check that the Zuko app is in the list of Installed apps.</li>
            <li className="mb-1">Next, click into <i>Customer events</i>, and check that the Status of the Zuko Pixel is 'Connected'. This confirms that the installation is using Zuko's tracking code on the checkout.</li>
          </ol>

          <h4 className="">Steps to check your data:</h4>
          <p>We recommend that you test your form tracking by filling it in, interact with your fields and then submit your form. Note: If you want to remove these 'test' sessions from your Zuko data, you can reset form data on the <Link to={`/forms/${form?.uuid}/edit`}>Details</Link> page.</p>
          <ol className="further-info-content">
            <li className="mb-1">
              <h5 className="list-title mb-0">View Zuko events being sent from your form</h5>
                Right click on the page your form is on, click Inspect, open the Network tab, filter on 'zuko', then check these <a href="https://www.zuko.io/guides/checking-your-code-installation" target="_blank" rel="noopener noreferrer">example events<HiExternalLink className="align-top"/></a>.</li>
            <li className="mb-1">
              <h5 className="list-title mb-0">View events being received by Zuko</h5>
                  View real-time <OverlayTrigger placement="top" overlay={<Popover>
                <Popover.Body>
                    A view event is counted when Zuko receives a <code>formView</code> event. This is sent in the form's activity tracking code.
                </Popover.Body>
              </Popover>}>
                <u className="text-more-info">View</u>
              </OverlayTrigger> and <OverlayTrigger placement="top" overlay={<Popover>
                <Popover.Body>
                    A completion event is counted when Zuko receives a <code>completion</code> event. This is sent in the form's completion code.
                </Popover.Body>
              </Popover>}>
                <u className="text-more-info">Completion</u>
              </OverlayTrigger> events in the Zuko app on the <Link to="/live">Live Events</Link> page.</li>
            <li className="mb-1">
              <h5 className="list-title mb-0">View sessions in Zuko reports</h5>
                  Typically, sessions are available in reports within 30 mins from a final event. If you have completed your form, your session will be available more quickly. After that, you can view your test session/s
                  in any of the reports, such as in <Link to="/form_aggregate">Form Data</Link> or review field-by-field in <Link to="/session_explorer">Session Explorer</Link>.</li>
          </ol>
          <p>If you haven't found an answer here or in the <a href="https://www.zuko.io/guides" target="_blank" rel="noopener noreferrer">guides<HiExternalLink className="align-top"/></a>, please
          use the in-app messaging, or email <a href="mailto:support@zuko.io">support@zuko.io</a> to contact us.</p>
        </div>
      </div>
    </div>);
};

export default CheckDataOnly;
