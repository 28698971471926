import React, { useState, useEffect, useContext }  from 'react';
import { useHistory } from 'react-router-dom';
import AppContext from '../../AppContext';
import { GoogleLogin } from '@react-oauth/google';
import * as Sentry from '@sentry/react';

import api from '../../api';
import { splitName } from '../../utils';

const takenEmailErrorMsg = 'An account already exists for this email address. Please <a href="/login">login</a> or try another email.';
const invalidEmailErrorMsg = 'This email is invalid. Please try a different email, or contact <a href="mailto:support@zuko.io">Zuko support</a>.';
const mainErrorMsg = "We're sorry, something has gone wrong creating your account. Please try again or contact <a href='mailto:support@zuko.io'>Zuko support</a>.";

const GoogleOAuthLogin = ({
  mixpanel,
  product = 'analytics',
  setGoogleSignUpLoading,
  autoLoginTo,
}) => {
  const { setUser } = useContext(AppContext);
  const history = useHistory();
  const [mainError, setMainError] = useState(null);

  const handleGoogleSignUp = async ({credential}) => {
    window.zukoTrackingInstance?.setAttribute('usedOAuth', true);
    window.zukoTrackingInstance?.trackEvent({
      type: 'click',
      target: (() => {
        const button = document.createElement('button');
        button.id = 'google-o-auth';
        return button;
      })(),
    });

    try {
      setGoogleSignUpLoading(true);
      setMainError(null);

      const { data: {user} } = await api.post('/oauth/google/sign_up', {
        user: {
          role: 'admin',
        },
        token: credential,
      });

      window.zukoTrackingInstance?.trackEvent(window.Zuko?.COMPLETION_EVENT);

      // Push event to Gogle data layer
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({event: 'analytics_user_sign_up_completed'});

      setUser(user);

      const { email, name } = user;
      const profile = {
        $email: email
      };

      if (name) {
        const nameMatch = splitName(name);
        const firstName = nameMatch[1];
        const lastName = nameMatch[2];
        if (firstName) profile.$first_name = firstName;
        if (lastName) profile.$last_name = lastName;
      }

      mixpanel.people.set(profile);
      mixpanel.identify(email);
      mixpanel.track('Completed Sign Up', { page: 'Sign Up', product, oAuth: 'google', 'Organisation Name': 'Organisation',
        'Organisation Contract Type': 'trial',
      });

      mixpanel.track('Login', { oAuth: 'google', product,
        'First Organisation Name': 'Organisation',
        'First Organisation Contract Type': 'trial',
      });

      if (autoLoginTo) {
        setUser(user);
        mixpanel.track('Login', { product,
          'First Organisation Name': user.organisations[0].name,
          'First Organisation Contract Type': 'trial',
        });
        history.push(autoLoginTo, {signUpSuccess: true});
      } else {
        history.push('/dashboard', {signUpSuccess: true});
      }
    } catch (e) {
      if (e.response && e.response.status === 400 && e.response.data.errors && Object.keys(e.response.data.errors)?.length > 0) {
        const { name, email } = e.response.data.errors;
        if (name) {
          setMainError(name.map((e) => `Name ${e}`).join(',') );
        }
        if (email) {
          setMainError(email.map((e) => e === 'is invalid' ? invalidEmailErrorMsg : e === 'has already been taken' ? takenEmailErrorMsg : `Email ${e}`).join(',') );
        }
      } else {
        Sentry.captureException(e);
        setMainError(mainErrorMsg);
      }
    } finally {
      setGoogleSignUpLoading(false);
    }
  };

  useEffect(() => {
    if (mainError) window.zukoTrackingInstance?.trackEvent({type: mainError});
  }, [mainError]);

  return (
    <div className="oauth-container mx-auto">
      <div className="signup-divider text-center d-flex align-items-center">
        <hr className="mx-auto"/><p className="mb-0">Or</p><hr className="mx-auto"/>
      </div>
      <div className="d-flex justify-content-center google-button-container">
        <GoogleLogin
          type="standard"
          theme="outline"
          size="large"
          text="continue_with"
          width="220px"
          context="signup"
          onSuccess={handleGoogleSignUp}
          onError={() => setMainError('Sorry something went wrong. Please try again or sign up with email.')}
        />
      </div>
    </div>

  );
};

export default GoogleOAuthLogin;
