import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import CapitalOneLogo from '../../images/logos/capital-one-zuko-logo.png';
import DfsLogo from '../../images/logos/dfs-zuko-logo.png';
import ExperianLogo from '../../images/logos/experian-zuko-logo.png';
import TipicoLogo from '../../images/logos/tipico-zuko-logo.png';
import HiscoxLogo from '../../images/logos/hiscox-zuko-logo.png';
import EE2023 from '../../images/awards/EE 2023.png';
import EE2024 from '../../images/awards/ExElite Accreditation 4.png';

const SocialProof = () => {
  return (<>
    <div className="divider"></div>
    <Row className="g-0 awards-row justify-content-center mb-4">
      <Col className="p-0 d-flex justify-content-center">
        <div className="">
          <div className="d-flex flex-column justify-content-center">
            <div className="d-flex flex-row justify-content-evenly">
              <img src={EE2024} alt="Best CRO Analytics Tool winner" className="award-logo"></img>
              <img src={EE2023} alt="Best Vendor for Customer Service winner" className="award-logo"></img>
            </div>
            <p className="text-center mb-2">Winner of the <b>“CRO Analytics Tool of the Year”</b> at the Experimentation Elite Awards 2024</p>
            <p className="text-center mb-0">Winner of the <b>“Best Vendor for Customer Service”</b> at the Experimentation Elite Awards 2023</p>
          </div>
        </div>
      </Col>
    </Row>
    <div className="logos mx-auto pt-4">
      <img src={CapitalOneLogo} alt="Capital One Logo"></img>
      <img src={DfsLogo} alt="Dfs Logo" width={'65px'}></img>
      <img src={ExperianLogo} alt="Experian Logo"></img>
      <img className="py-1" src={HiscoxLogo} alt="Hiscox Logo" width={'90px'}></img>
      <img src={TipicoLogo} alt="Tipico Logo" width={'94px'}></img>
    </div>
    <div className="mt-4 d-flex justify-content-center logo-text-row">
      <p className="mb-0">Just a few of the companies who use Zuko.</p>
    </div>
  </>
  );
};

export default SocialProof;
