import React, { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { Link, useLocation, useParams, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import TabContent from 'react-bootstrap/TabContent';
import TabPane from 'react-bootstrap/TabPane';
import TabContainer from 'react-bootstrap/TabContainer';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import { VscWarning, VscCheck, VscInfo } from "react-icons/vsc";

import NavBar from '../../NavBar';
import AppAlerts from '../../Components/AppAlerts';
import api from '../../api';
import AppContext from '../../AppContext';
import FeedbackRow from '../../Components/FeedbackRow';
import FormDetailsForm from '../../Components/FormDetailsForm';
import ReadOnlyFormItems from './ReadOnlyFormItems';
import { usePrevious } from '../../hooks';
import Forms from '../../forms';

import './FormEdit.scss';

const FormEdit = ({mixpanel}) => {
  const { uuid: formUuid } = useParams();
  const location = useLocation();
  const history = useHistory();
  const { currentUser, query, setQuery } = useContext(AppContext);
  const [form, setForm] = useState(location.state?.form);
  const [mainError, setMainError] = useState(null);
  const [showErrorMessagesForField, setShowErrorMessagesForField] = useState(false);
  const [errorMessagesForField, setErrorMessagesForField] = useState(); // Object response with error messages per field
  const [errorMessages, setErrorMessages] = useState(); // Array of messages
  const [errorMessage, setErrorMessage] = useState(); // Standard catch all single message
  const [formUpdateSuccessMessage, setFormUpdateSuccessMessage] = useState();
  const [showDeleteConf, setShowDeleteConf] = useState(false);
  const [showDeletedForm, setShowDeletedForm] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [showResetConfig, setShowResetConfig] = useState(false);
  const [resetUuid, setResetUuid] = useState(true); // By default, select to reset session data
  const [resetFields, setResetFields] = useState();
  const [resetAlerts, setResetAlerts] = useState();
  const [resetSegments, setResetSegments] = useState();
  const [resetFunnelStages, setResetFunnelStages] = useState();
  const [resetAllData, setResetAllData] = useState();
  const [formResetErrorMessage, setFormResetErrorMessage] = useState();
  const [formResetSuccessMessage, setFormResetSuccessMessage] = useState();

  const pageTitleRef = useRef();

  const prevForm = usePrevious(form);

  const handleResetErrorMessages = useCallback(() => {
    if (errorMessage) setErrorMessage(null);
    if (errorMessages) setErrorMessages(null);
    if (errorMessagesForField) setErrorMessagesForField(null);
    if (formResetErrorMessage) setFormResetErrorMessage(null);
  },[errorMessage, errorMessages, errorMessagesForField, formResetErrorMessage]);

  const handleResetErrorMessagesForField = useCallback((field) => {
    if (errorMessagesForField?.[field]) setErrorMessagesForField(messages => {
      const newMessages = {...messages};
      delete newMessages[field];
      return newMessages;
    });
  },[errorMessagesForField]);

  const handleResetSuccessMessage = useCallback(() => {
    if (formUpdateSuccessMessage) setFormUpdateSuccessMessage(null);
    if (formResetSuccessMessage) setFormResetSuccessMessage(null);
  },[formUpdateSuccessMessage, formResetSuccessMessage]);

  useEffect(() => {
    mixpanel.identify(currentUser.email);
    if (form && (!prevForm || (prevForm?.uuid !== form.uuid))) {
      mixpanel.track('Page View', {
        page: 'Edit Form',
        'Form Uuid': form.uuid,
        'Organisation Name': form.organisation.name,
        'Organisation Uuid': form.organisation.uuid,
        'Organisation Contract Type': form.organisation.contractType,
      });
    }
  }, [mixpanel, currentUser.email, form, prevForm]);

  useEffect(() => {
    // Not linked to from another tab so fetch the form
    if (!location?.state?.form) {
      (async () => {
        try {
          let { data: { form } } = await api.get(`/forms/${formUuid}`);
          setForm(form);
        } catch (e) {
          switch (e?.response?.status) {
          case 401:
            setMainError('Not logged in');
            break;
          case 403:
            history.replace('/');
            break;
          case 404:
            setMainError('Form not found');
            break;
          default:
            setMainError('Something went wrong. Form Details could not load.');
          }
        }
      })();
    }

  }, [formUuid, location, history]);


  const handleSubmit = async ({label, contact, url, weeklyEmailEnabled, industry, purpose}) => {
    if (saveDisabled) return;
    setSaveDisabled(true);

    try {
      handleResetErrorMessages();
      await Forms.update(formUuid, {
        contact,
        label,
        ...!url?.includes('myshopify.com') && {url},
        weeklyEmailEnabled,
        industry,
        purpose,
      }, form.organisation);
      setFormUpdateSuccessMessage(`Form updated. ${(url !== form.url) ? 'As the URL was edited, please ensure that the tracking code is installed on the correct form.' : ''}`);
      setForm({...form, contact, label, url, weeklyEmailEnabled, industry, purpose});
      window.scrollTo({ behavior: 'smooth', top: pageTitleRef.current.offsetTop });
      if (query?.form?.uuid === formUuid) setQuery({...query, form: {...query.form, contact, label, url, weeklyEmailEnabled, industry, purpose}});
      mixpanel.track('Updated Form', {
        page: 'Edit Form',
        'Form Label': label,
        'Form Uuid': formUuid,
        'Organisation Name': form.organisation.name,
        'Organisation Uuid': form.organisation.uuid,
        'Organisation Contract Type': form.organisation.contractType,
      });
      history.replace({ ...history.location, state: {form: {...form, contact, label, url, weeklyEmailEnabled, industry, purpose} }}); // Update the location state so that on page refresh the updated form details are used
    } catch (e) {
      switch (e?.response?.status) {
      case e?.response?.data?.errors && 400:
      case e?.response?.data?.errors && 422:
        if (Array.isArray(e.response.data.errors)) {
          setErrorMessages(e.response.data.errors);
        } else {
          setErrorMessagesForField(e.response.data.errors);
          setShowErrorMessagesForField(true);
        }
        break;
      case 401:
        setMainError('Not logged in');
        break;
      case 403:
        history.replace('/');
        break;
      default:
        setErrorMessage('Something went wrong. The form has not been updated, please try again.');
      }
      window.scrollTo({ behavior: 'smooth', top: pageTitleRef.current.offsetTop });
    } finally {
      setSaveDisabled(false);
    }
  };

  const handleDelete = async () => {
    try {
      await api.delete(`/forms/${formUuid}`);
      setShowDeleteConf(false);
      setShowDeletedForm(true);
      // TODO: go to Org with the 'Form deleted' success message rather than stay on this page with the old uuid
      window.scrollTo({ behavior: 'smooth', top: pageTitleRef.current.offsetTop });
      mixpanel.track('Deleted Form', {
        page: 'Edit Form',
        'Form Label': form.label,
        'Form Uuid': formUuid,
        'Organisation Name': form.organisation.name,
        'Organisation Uuid': form.organisation.uuid,
        'Organisation Contract Type': form.organisation.contractType,
      });
      Forms.reload(); // Trigger re-loading of forms list (app-wide)
      if (query?.form?.uuid === formUuid) setQuery({...query, form: {}}); // Reset the form so a new defualt cna be chosen
    } catch (e) {
      setShowDeleteConf(false);
      setErrorMessage('Something went wrong. Form could not be deleted');
      window.scrollTo({ behavior: 'smooth', top: pageTitleRef.current.offsetTop });
    }
  };

  const handleReset = async () => {
    try {
      const items = [
        resetUuid && 'uuid',
        resetFields && 'fields',
        resetAlerts && 'alerts',
        resetSegments && 'segments',
        resetFunnelStages && 'funnel_stages',
      ].filter(i => i);

      if (items.length < 1) return;

      const {data: {form: updatedForm}} = await api.put(`/forms/${formUuid}/reset`, {items});

      mixpanel.track('Reset Form', {
        page: 'Edit Form',
        'Form Label': form.label,
        ...items.includes('uuid') && {'Prev Form Uuid': formUuid},
        'Form Uuid': updatedForm.uuid,
        'Organisation Name': updatedForm.organisation.name,
        'Organisation Uuid': updatedForm.organisation.uuid,
        'Organisation Contract Type': updatedForm.organisation.contractType,
        'Items reset': items,
      });

      setFormResetSuccessMessage(`The following data has been successfully reset: ${items.map(i => i === 'uuid' ? 'session data' : i ).join(', ')}.`);
      Forms.reload();// Trigger re-loading of forms list (app-wide)
      if ((query?.form?.uuid === formUuid) && (formUuid !== updatedForm.uuid)) {
        setQuery({...query, form: {...query.form, ...updatedForm}});  // Set the right form uuid back into state
      }

      if (!query?.form?.uuid) {
        setQuery({form: {...updatedForm}});  // Provide the form to be used in the navbar for a link to a report page
      }

      history.push(`/forms/${updatedForm.uuid}/edit`);
    } catch (e) {

      if (e.response && e.response.status === 400 && e.response.data.errors && Object.keys(e.response.data.errors).length > 0) {
        setErrorMessages(e.response.data.errors);
        setFormResetErrorMessage('Sorry, selected data was not reset. Please Save the changes on the form then try reset again.');
      } else {
        setFormResetErrorMessage('Sorry, something went wrong. Selected data was not reset.');
      }
    } finally {
      setShowResetConfig(false);
      window.scrollTo({ behavior: 'smooth', top: pageTitleRef.current.offsetTop });
    }
  };

  return (
    <Container fluid className="form-edit page">
      <Helmet titleTemplate="%s | Zuko" defaultTitle="Zuko" defer={false}>
        <title>Edit Form</title>
      </Helmet>
      <div className="nav-wrapper">
        <NavBar mixpanel={mixpanel}/>
      </div>
      <div className="main-content">
        <Col className="center-column justify-content-md-center">
          <FeedbackRow
            classList={['allow-scroll-under-nav']}
            mixpanel={mixpanel}
            page={'Edit Form'}
            org={form?.organisation}
            messageContent={'editing a Form'} />
          <AppAlerts showOrgAlerts={true} />
          <Row className="title-row g-0" ref={pageTitleRef}>
            <Col className="p-0">
              <h1 id="form-title">
                {form && !showDeletedForm && (<>
                  {`${form.organisation.name} | ${form.label} | `}
                  <a href={form.url} target="_blank" rel="noopener noreferrer">{form.url}</a></>)
                }
              </h1>
            </Col>
          </Row>
          <Card className="edit-card">
            <Card.Body>
              <TabContainer defaultActiveKey="details">
                <Row className="g-0 card-title-row justify-content-between">
                  <Col className="p-0">
                    <Card.Title as="h3">Edit Form</Card.Title>
                  </Col>
                  <Col className="p-0 col-auto">
                    <Nav variant="tabs" className="justify-content-end" id="card-header-nav-tabs">
                      <Nav.Item>
                        <Nav.Link eventKey="details" disabled={showDeletedForm}>Details</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link as={Link} to={{pathname:`/forms/${formUuid}/fields`, state: {form}}} disabled={showDeletedForm}>Label Fields</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link as={Link} to={{pathname:`/forms/${formUuid}/integrations`, state: {form}}} disabled={showDeletedForm}>Integrations</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link as={Link} to={{pathname: `/forms/${formUuid}/tracking_code`, state: {form}}} disabled={showDeletedForm}>Tracking Code</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                </Row>
                <Row className="g-0 card-content">
                  <TabContent>
                    {mainError && <div id="form-load-error"><h3>{mainError}</h3></div>}
                    <TabPane eventKey="details">
                      {formUpdateSuccessMessage &&
                        <Row className="alert-row g-0">
                          <Alert dismissible variant="success" closeVariant="white"
                            onClose={() => setFormUpdateSuccessMessage(null)}>
                            <div className="alert-svg-icon my-auto"><VscCheck size="100%"/></div>
                            <p className="alert-text m-0">{formUpdateSuccessMessage}</p>
                          </Alert>
                        </Row>}
                      {showErrorMessagesForField && (errorMessagesForField && Object.keys(errorMessagesForField).length > 0) &&
                        <Row className="alert-row g-0">
                          <Alert dismissible variant="danger" closeVariant="white"
                            onClose={() => setShowErrorMessagesForField(false)}>
                            <div className="alert-svg-icon my-auto"><VscWarning size="100%"/></div>
                            <div>
                              {Object.keys(errorMessagesForField)
                                .map((field, i) => (
                                  <p key={i} className="alert-text m-0 py-1">{field === 'domain' ? 'URL is not recognised.' : `${field} ${errorMessagesForField[field]?.join(', ')}`}</p>))}
                            </div>
                          </Alert>
                        </Row>
                      }
                      {errorMessages?.length > 0 &&
                        <Row className="alert-row g-0">
                          <Alert dismissible variant="danger" closeVariant="white"
                            onClose={() => setErrorMessages(null)}>
                            <div className="alert-svg-icon my-auto"><VscWarning size="100%"/></div>
                            <p className="alert-text m-0">{errorMessages.map((e) => e.message)}</p>
                          </Alert>
                        </Row>
                      }
                      {errorMessage &&
                        <Row className="alert-row g-0">
                          <Alert dismissible variant="danger" closeVariant="white"
                            onClose={() => setErrorMessage(null)}>
                            <div className="alert-svg-icon my-auto"><VscWarning size="100%"/></div>
                            <p className="alert-text m-0">{errorMessage}</p>
                          </Alert>
                        </Row>
                      }
                      {formResetSuccessMessage &&
                        <Row className="alert-row g-0">
                          <Alert dismissible variant="success" closeVariant="white"
                            onClose={() => setFormResetSuccessMessage(false)}>
                            <div className="alert-svg-icon my-auto"><VscCheck size="100%"/></div>
                            <p className="alert-text m-0">{formResetSuccessMessage}</p>
                          </Alert>
                        </Row>}
                      {formResetErrorMessage &&
                        <Row className="alert-row g-0">
                          <Alert dismissible variant="danger" closeVariant="white"
                            onClose={() => setFormResetErrorMessage(false)}>
                            <div className="alert-svg-icon my-auto"><VscWarning size="100%"/></div>
                            <p className="alert-text m-0">{formResetErrorMessage}</p>
                          </Alert>
                        </Row>}
                      {showDeletedForm ? <>
                        <Row className="alert-row g-0">
                          <Alert variant="success" onClose={() => setShowDeletedForm(false)}>
                            <div className="alert-svg-icon my-auto"><VscCheck size="100%"/></div>
                            <p className="alert-text m-0">Form deleted.</p>
                          </Alert>
                        </Row>
                        <p>Return to my <Link to={`/organisations/${form.organisation.uuid}/edit`}>Organisation</Link>.</p>
                      </> :
                        <div className="d-grid details-grid">
                          <FormDetailsForm
                            handleSubmit={handleSubmit}
                            type={'edit'}
                            form={form}
                            handleResetErrorMessagesForField={handleResetErrorMessagesForField}
                            errorMessagesForField={errorMessagesForField}
                            saveDisabled={saveDisabled}
                            handleResetSuccessMessage={handleResetSuccessMessage}
                          />

                          <ReadOnlyFormItems form={form} />

                          <div className="form-actions">
                            <h4>Actions</h4>
                            <p className="card-tagline">Manage your form data or delete this form entirely.</p>
                            <Row>
                              <Col className="p-0">
                                <Button variant="outline-danger" className="ms-0 mt-2"
                                  onClick={() => {
                                    setShowResetConfig(true);
                                    handleResetErrorMessages();
                                    if (handleResetSuccessMessage) handleResetSuccessMessage();
                                  }}>Reset Data</Button>
                                <div className="input-feedback">Reset all form data, or choose which parts to reset for example session data, configured fields, funnel stages, segments or alerts.</div>
                              </Col>
                            </Row>
                            <Row className="mt-2">
                              <Col className="p-0">
                                <Button variant="outline-danger" className="ms-0 mt-2"
                                  onClick={() => {
                                    setShowDeleteConf(true);
                                    handleResetErrorMessages();
                                    if (handleResetSuccessMessage) handleResetSuccessMessage();
                                  }}>Delete Form</Button>
                                <div className="input-feedback">Delete this form and all associated sessions, fields, funnels, segments and alerts.</div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      }

                      <Modal show={showDeleteConf} onHide={() => setShowDeleteConf(false)}>
                        <Modal.Header>
                          <Modal.Title>Delete Form: {form?.label}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          {form?.url?.includes('myshopify.com') &&
                            <Alert variant="info"
                              onClose={() => setShowErrorMessagesForField(false)}>
                              <div className="page-alert-svg-icon d-flex"><VscInfo size="100%"/></div>
                              <p className="alert-text m-0">This form cannot be deleted as it is linked to a Shopify Checkout. Please contact <a href="mailto:support@zuko.io">support</a> to process your request.</p>
                            </Alert>
                          }
                          <p><strong>A Form cannot be restored</strong>.</p>
                          <p>Deleting it will also delete any associated <i>Fields</i> you may have labelled, and <i>Funnel</i>, <i>Alerts</i> or <i>Segments</i> in a <i>Segment Comparison</i> you may have created.</p>
                        </Modal.Body>
                        <Modal.Footer className="justify-content-between">
                          <Button variant="outline-secondary" className="cancel mx-1" onClick={() => setShowDeleteConf(false)}>Cancel</Button>
                          <Button variant="danger" className="mx-1" disabled={form?.url?.includes('myshopify.com') || false} onClick={handleDelete}>OK, please Delete</Button>
                        </Modal.Footer>
                      </Modal>

                      <Modal show={showResetConfig} onHide={() => setShowResetConfig(false)} id="reset-data-modal">
                        <Modal.Header>
                          <Modal.Title>Reset Form Data: {form?.label}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <p>You can reset all form data, or choose which parts to reset.</p>
                          <div className="px-3">
                            <Form.Check label="Reset Session Data" type="checkbox" name="reset-session-data" id="reset-session-data" checked={resetUuid || false} onChange={() => setResetUuid(!resetUuid)} />
                            <p className="reset-info mb-1">Start from scratch with your sessions. You may want to do this if you've made big changes in your form and don't want to view stats for old data. This old data will still count towards your <Link to={`/users/${currentUser.uuid}/organisations_usage`} target="_blank">Organisation Usage</Link>, but it won't be linked to this form.</p>
                            <p className="reset-info"><strong>Note:</strong> Your tracking code stays the same. New events received will be allocated to this form.</p>

                            <Form.Check label="Reset Configured Fields" type="checkbox" name="reset-fields" id="reset-fields" checked={resetFields || false} onChange={() => setResetFields(!resetFields)} />
                            <p className="reset-info">You can keep your <Link to={`/forms/${formUuid}/fields`} target="_blank">Configured Fields</Link> that you have labelled and ordered - or tick to delete them all. You can then label and order newly tracked fields.</p>

                            <Form.Check label="Reset Funnel Stages" type="checkbox" name="reset-funnel-stages" id="reset-funnel-stages" checked={resetFunnelStages || false} onChange={() => setResetFunnelStages(!resetFunnelStages)} />
                            <p className="reset-info">If you have created a <Link to={`/funnel`} target="_blank">Funnel</Link> and added your fields, you can keep this all in place, or tick to delete the whole funnel.</p>

                            <Form.Check label="Reset Segments" type="checkbox" name="reset-segments" id="reset-segments" checked={resetSegments || false} onChange={() => setResetSegments(!resetSegments)} />
                            <p className="reset-info">You can keep segments that you have configured in a <Link to={`/form-segment-comparison`} target="_blank">Form Segment Comparison</Link> or <Link to={`/field-segment-comparison`} target="_blank">Field Segment Comparison</Link>, or tick to delete all Segments for this form.</p>

                            <Form.Check label="Reset Alerts" type="checkbox" name="reset-alerts" id="reset-alerts" checked={resetAlerts || false} onChange={() => setResetAlerts(!resetAlerts)} />
                            <p className="reset-info">If you have any configured <Link to={`/organistions/${form?.organisation.uuid}/alerts`} target="_blank">Alerts</Link>, you can keep these in place, or tick to delete them.</p>

                            <Form.Check label="Reset All" type="checkbox" name="reset-all" id="reset-all" checked={resetAllData || false} className="pt-2"
                              onChange={() => {
                                setResetAllData(!resetAllData);
                                setResetAlerts(!resetAllData);
                                setResetSegments(!resetAllData);
                                setResetFunnelStages(!resetAllData);
                                setResetFields(!resetAllData);
                                setResetUuid(!resetAllData);
                              }} />
                          </div>
                        </Modal.Body>
                        <Modal.Footer className="justify-content-between">
                          <Button variant="outline-secondary" className="cancel mx-1" onClick={() => setShowResetConfig(false)}>Cancel</Button>
                          <Button variant="danger" className="mx-1" onClick={handleReset} disabled={!resetUuid && !resetFields && !resetAlerts && !resetSegments && !resetFunnelStages}>Reset Selected Data</Button>
                        </Modal.Footer>
                      </Modal>
                    </TabPane>
                  </TabContent>
                </Row>
              </TabContainer>
            </Card.Body>
          </Card>
        </Col>
      </div>
    </Container>
  );
};

export default FormEdit;
