import React, { useEffect, useContext, useState, useCallback, useMemo } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import FormGroup from 'react-bootstrap/FormGroup';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import { VscCheck, VscWarning } from 'react-icons/vsc';
import { FaSpinner } from 'react-icons/fa';
import qs from 'qs';

import NavBar from '../../../NavBar';
import AppContext from '../../../AppContext';
import api from '../../../api';
import FeedbackRow from '../../../Components/FeedbackRow';
import {
  orgDetailsForMixpanel,
} from '../../../utils';
import Forms from '../../../forms';

const Shopify = ({mixpanel, installCodeErrorMessage}) => {
  const history = useHistory();
  const location = useLocation();
  const { currentUser, apiBaseUrl } = useContext(AppContext);
  const params = useMemo(() => qs.parse(location.search, { ignoreQueryPrefix: true }), [location.search]);
  const { shop } = params;
  const shopUrl = shop && `https://${shop}`;
  const [formForShop, setFormForShop] = useState();

  const [showSignUpSuccessMsg, setShowSignUpSuccessMsg] = useState(location.state?.signUpSuccess);
  const [showPixelInstalledSuccessMsg, setShowPixelInstalledSuccessMsg] = useState();
  const [showCreatingPixelError, setShowCreatingPixelError] = useState();
  const [isCreating, setIsCreating] = useState();
  const [formCreateError, setFormCreateError] = useState(); // Standard catch all single message
  const [isSubmitting, setIsSubmitting] = useState(false); // Disable the button during submit
  const [formForShopLoading, setFormForShopLoading] = useState(true);
  const [orgFormsError, setOrgFormsError] = useState(null);
  const [label, setLabel] = useState('Checkout');
  const org = currentUser.organisations[0]; // Default to first Org

  const handleAddForm = useCallback(async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);

    try {
      setFormCreateError(null);
      setIsCreating(true);
      setShowCreatingPixelError(false);
      const { data: { form } } = await api.post('/forms', {
        form: {
          organisationUuid: org.uuid,
          contactUuid: currentUser.uuid,
          label: label || 'Checkout',
          url: shopUrl,
          weeklyEmailEnabled: true,
        }
      });

      mixpanel.track('Created Form', { 'Form Label': label,
        page: 'Shopify Integrations',
        ...org && {
          'Organisation Name': org.name,
          'Organisation Uuid': org.uuid,
          'Organisation Contract Type': org.contractType,
        },
      });

      Forms.reload(); // Trigger re-loading of forms list (app-wide)

      setFormForShop(form);
    } catch (e) {
      switch (e?.response?.status) {
      case 401:
        setFormCreateError('Not logged in');
        break;
      case 403:
        history.replace('/');
        break;
      default:
        setFormCreateError('Something went wrong. The form has not been created, please try again.');
      }
      setIsCreating(false);
    } finally {
      setIsSubmitting(false);
    }
  }, [label, currentUser.uuid, history, isSubmitting, org, mixpanel, shopUrl]);

  useEffect(() => {
    mixpanel.identify(currentUser.email);
    mixpanel.track('Page View', { page: 'Shopify Integrations', ...orgDetailsForMixpanel(org)});
  }, [mixpanel, currentUser.email, org]);

  useEffect(() => {
    if (org?.uuid) (async () => {
      try {
        setFormForShopLoading(true);
        setOrgFormsError(null);
        const { data: { forms } } = await api.get(`/organisations/${org.uuid}/forms`);
        setFormForShop(forms?.find(f => f.url === shopUrl));
      } catch (e) {
        setOrgFormsError((e.response && (e.response.status === 404)) ? 'Organisation not found' :
          (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong');
      } finally {
        setFormForShopLoading(false);
      }
    })();
  }, [org?.uuid, shopUrl]);

  useEffect(() => {
    if (formForShop?.uuid) {
      (async () => {
        try {
          setIsCreating(true);
          setShowCreatingPixelError(false);
          const {data: {id: shopifyWebPixelId}} = await api.post('/integrations/shopify/web_pixels', {
            shop,
            formUuid: formForShop.uuid
          });

          // If there is a pixel on the response, this is a new or refreshed install
          if (shopifyWebPixelId) {
            setFormForShop(prev => ({...prev, shopifyWebPixelId}));
            setShowPixelInstalledSuccessMsg(true);
            mixpanel.track('Completed Pixel Creation', { page: 'Pixel Create', ...orgDetailsForMixpanel(org)});
          }
          setIsCreating(false);
        } catch (e) {
          switch (e?.response?.status) {
          case 400:
            if (e.response.data.error.includes('Valid access token not found')) {
              window.location.href = `${apiBaseUrl}/oauth/shopify/install?shop=${shop}`;
            } else {
              setShowCreatingPixelError(true);
              setIsCreating(false);
            }
            break;
          default:
            setShowCreatingPixelError(true);
            setIsCreating(false);
          }
        }
      })();
    }
  }, [formForShop?.uuid, mixpanel, org, shop, apiBaseUrl]);

  return (<>
    <div className="nav-wrapper browser-only">
      <NavBar mixpanel={mixpanel} />
    </div>
    <div className="main-content shopify">
      <Col className="center-column justify-content-md-center">
        <FeedbackRow
          classList={['allow-scroll-under-nav']}
          mixpanel={mixpanel}
          page={'Shopify Integrations'}
          org={currentUser}
          messageContent={`Shopify integrations`} />
        {showSignUpSuccessMsg &&
          <Row className="alert-row g-0 my-3">
            <Alert dismissible variant="success" closeVariant="white"
              onClose={() => setShowSignUpSuccessMsg(false)}>
              <div className="alert-svg-icon my-auto"><VscCheck size="100%"/></div>
              <p className="alert-text m-0">Welcome to Zuko. To log back in later, please check your <strong>{currentUser?.email}</strong> inbox. And follow the link to set your password.</p>
            </Alert>
          </Row>}
        <Row className="title-row g-0">
          <Col className="p-0">
            <h1 id="form-title">
              {org && org.name && org.name}
            </h1>
          </Col>
        </Row>
        <Card>
          <Card.Body>
            <Row className="g-0 card-title-row justify-content-between">
              <Col className="p-0">
                <Card.Title as="h3">Shopify Integrations</Card.Title>
              </Col>
            </Row>
            <Row className="g-0 card-content">
              {installCodeErrorMessage &&
                <Row className="alert-row g-0 mt-0">
                  <Alert variant="danger">
                    <div className="alert-svg-icon my-auto"><VscWarning size="100%"/></div>
                    <p className="alert-text m-0">{installCodeErrorMessage}</p>
                  </Alert>
                </Row>}
              {!shopUrl &&
                <Row className="alert-row g-0 mt-0">
                  <Alert variant="danger">
                    <div className="alert-svg-icon my-auto"><VscWarning size="100%"/></div>
                    <p className="alert-text m-0">The shop URL is missing. Please return to your Shopify account and click on the Zuko Checkout Analytics app.</p>
                  </Alert>
                </Row>}
              {formForShopLoading && <div className="d-inline-flex justify-content-center">
                <FaSpinner size="20px" className="spinning-icon me-2"/>
                <p className="mb-0">Loading forms...</p>
              </div>}
              {orgFormsError &&
                <Row className="alert-row g-0 mt-0">
                  <Alert variant="danger" closeVariant="white">
                    <div className="alert-svg-icon my-auto"><VscWarning size="100%"/></div>
                    <p className="alert-text m-0">{orgFormsError}</p>
                  </Alert>
                </Row>}
              {showPixelInstalledSuccessMsg &&
                <Row className="alert-row g-0 my-3">
                  <Alert dismissible variant="success" closeVariant="white"
                    onClose={() => setShowSignUpSuccessMsg(false)}>
                    <div className="alert-svg-icon my-auto"><VscCheck size="100%"/></div>
                    <p className="alert-text m-0">Zuko has been successfully installed on your Shopify Checkout.</p>
                  </Alert>
                </Row>}
              {(!formForShopLoading && !orgFormsError && !formForShop && !isCreating && shopUrl) && <>
                <p>You can now add your form to complete the integration. Analytics tracking will then be installed on your Shopify Checkout.</p>
                {formCreateError &&
                  <Row className="alert-row g-0 mt-0">
                    <Alert variant="danger" closeVariant="white">
                      <div className="alert-svg-icon my-auto"><VscWarning size="100%"/></div>
                      <p className="alert-text m-0">{formCreateError}</p>
                    </Alert>
                  </Row>}
                <Form className="zuko-app-form" id="form-details-form" noValidate>
                  <FormGroup className="form-group" controlId="label">
                    <Form.Label>Label</Form.Label>
                    <Form.Control type="text" value={label || ''} required
                      onChange={(e) => {
                        setLabel(e.target.value);
                      }}/>
                    <div className="input-feedback">A label identifies your form in the Zuko App. E.g. Checkout form</div>
                  </FormGroup>
                  <FormGroup className="form-group" controlId="url">
                    <Form.Label>Store URL</Form.Label>
                    <Form.Control type="url" value={shopUrl} required disabled={true} />
                    <div className="input-feedback">This is fixed as your default Shopify store URL. Even if you are using a custom domain name, Zuko uses the original URL here.</div>
                  </FormGroup>
                  <Row className="form-actions-row">
                    <Col className="p-0">
                      <Button variant="primary" className="submit m-0" type="submit" disabled={isSubmitting} onClick={handleAddForm}>Complete Integration</Button>
                    </Col>
                  </Row>
                </Form>
              </>}
              {showCreatingPixelError && !isCreating &&
                  <Row className="alert-row g-0 mt-0">
                    <Alert variant="danger" closeVariant="white">
                      <div className="alert-svg-icon my-auto"><VscWarning size="100%"/></div>
                      <p className="alert-text m-0">Sorry, something went wrong installing the tracking code. Please refresh the page, or contact <a href="mailto:support@zuko.io">support</a>.</p>
                    </Alert>
                  </Row>}
              {isCreating && (!formForShop || (formForShop && !formForShop.shopifyWebPixelId)) && <>
                <Row className="alert-row g-0 mt-3">
                  <Alert variant="info" closeVariant="white">
                    <div className="alert-svg-icon my-auto me-3"><FaSpinner size="16px" className="spinning-icon" title="Connecting to Shopify Checkout.."/></div>
                    <p className="alert-text m-0">We are connecting to your Checkout and installing the Zuko Analytics tracking code. You may be redirected to Shopify if re-authorisation is required.</p>
                  </Alert>
                </Row>
              </>}
              {formForShop?.shopifyWebPixelId && <>
                <p>Here is your Zuko form that is connected to your Shopify Checkout.</p>
                <Table responsive id="forms-table">
                  <thead>
                    <tr>
                      <th className="w-25">Label</th>
                      <th className="w-50">Store URL</th>
                      <th className="link-col"></th>
                      <th className="link-col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="w-25">{formForShop?.label}</td>
                      <td className="w-50">{formForShop?.url}</td>
                      <td className="link-col"><Link to={`/form_aggregate?form[uuid]=${formForShop?.uuid}`}>View Data</Link></td>
                    </tr>
                  </tbody>
                </Table>
              </>}
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </div></>);
};

export default Shopify;
