import React, { useEffect, useContext } from 'react';
import DefaultError from '../DefaultError';
import AppContext from '../../AppContext';

const IntegrationsError = ({mixpanel}) => {
  const { currentUser } = useContext(AppContext);

  useEffect(() => {
    if (currentUser?.email) {
      mixpanel.identify(currentUser?.email);
      mixpanel.track('Page View', { page: 'Integrations Error' });
    }
  }, [mixpanel, currentUser?.email]);

  return (<DefaultError title="Oops! Something went wrong" message="Please try again, or contact Zuko support." />);
};

export default IntegrationsError;
