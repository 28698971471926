import React, { useContext, useState } from 'react';
import { Link, useLocation, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { VscWarning, VscCheck, VscInfo } from "react-icons/vsc";
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { FaSpinner } from 'react-icons/fa';
import { GoogleLogin } from '@react-oauth/google';
import { splitName } from '../../utils';

import AppContext from '../../AppContext';
import api from '../../api';

import './Login.scss';

const Login = ({mixpanel}) => {
  const { currentUser, setUser } = useContext(AppContext);
  const location = useLocation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailIsValid, setEmailIsValid] = useState();
  const [emailErrorMsg, setEmailErrorMsg] = useState();
  const [passwordIsValid, setPasswordIsValid] = useState();
  const [passwordErrorMsg, setPasswordErrorMsg] = useState();
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [alert, setAlert] = useState((location.state && location.state.alert) ? location.state.alert : null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [mfaRequired, setMfaRequired] = useState(false);
  const [otp, setOtp] = useState();
  const [otpIsValid, setOtpIsValid] = useState();
  const [otpErrorMsg, setOtpErrorMsg] = useState();
  const [googleLoginLoading, setGoogleLoginLoading] = useState();

  const from = location.state?.from;
  const redirectMatch = location.search.match(/[^(?redirect=)].*/);
  const redirectTo = redirectMatch && (redirectMatch[0] + location.hash);
  let storedUser;
  try {
    storedUser = JSON.parse(localStorage.getItem('zukoAppUser'));
  } catch (e) {/* Do nothing - no user */}

  const handleLogin = async (e) => {
    e.preventDefault();

    if (isSubmitting) return;
    setIsSubmitting(true);

    setLoginError(null);
    setAlert(null);

    // Validate email and password inputs
    if (!mfaRequired && !e.currentTarget.checkValidity()) {
      if (!email) {
        setEmailIsValid(false);
        setEmailErrorMsg('Please enter your email.');
      }
      if (!password) {
        setPasswordIsValid(false);
        setPasswordErrorMsg('Please enter your password.');
      }
      setIsSubmitting(false);
      return;
    }

    // Validate otp input
    if (mfaRequired) {
      if (!otp) {
        setOtpIsValid(false);
        setOtpErrorMsg('Please enter the code displayed in your app.');
        setIsSubmitting(false);
        return;
      }
      if (otp && otp.length < 6) {
        setOtpIsValid(false);
        setOtpErrorMsg('Please enter all six numbers from the code displayed in your app.');
        setIsSubmitting(false);
        return;
      }
    }

    try {
      const { data: { user } } = await api.post('/login', { email, password, ...otp && {otp} });
      localStorage.clear();

      if (user.email) {
        const profile = {
          $email: user.email
        };

        if (user.name) {
          const nameMatch = splitName(user.name);
          const firstName = nameMatch[1];
          const lastName = nameMatch[2];
          if (firstName) profile.$first_name = firstName;
          if (lastName) profile.$last_name = lastName;
        }

        mixpanel.people.set(profile);
        mixpanel.identify(user.email);
        const firstOrg = user.organisations && (user.organisations.find(o => (o.contractType === 'monthly' || o.contractType === 'fixed')) || user.organisations.find(o => o.contractType === 'trial'));

        mixpanel.track('Login', {
          product: user.organisations?.[0]?.signUpProduct === 'builder' ? 'builder' : 'analytics',
          'With MFA': (mfaRequired && otp) ? true : false,
          ...firstOrg && {
            'First Organisation Name': firstOrg.name,
            'First Organisation Uuid': firstOrg.uuid,
            'First Organisation Contract Type': firstOrg.contractType,
          },
        });
      }

      setUser(user);
    } catch (e) {
      const defaultError = 'Something went wrong. Please try again and <a href="mailto: support@zuko.io">get in touch</a> if you need more help.';

      if (e.response) {
        switch (e.response.status) {
        case 401:
          if (e.response.data?.errors?.mfa?.includes('is required')) {
            setMfaRequired(true);
          } else {
            setLoginError(defaultError);
          }
          break;
        case 403:
          if (e.response.data?.errors?.mfa?.includes('is incorrect')) {
            setOtpIsValid(false);
            setOtpErrorMsg("We're sorry this code is invalid. Please try again.");
            setOtp(null);
          } else {
            setLoginError('Invalid email/password combination.');
          }
          break;
        case 404:
          if (e.response.data?.errors?.[0].message.includes(`Couldn't find User`)) {
            setLoginError('Sorry, email not recognised. You can sign up for an account <a href="/signup">here</a>.');
          } else  {
            setLoginError('Invalid email/password combination.');
          }
          break;
        default:
          setLoginError(defaultError);
          if (mfaRequired) setMfaRequired(false);
        }
      } else {
        setLoginError(defaultError);
        if (mfaRequired) setMfaRequired(false);
      }
      setIsSubmitting(false);
    }
  };

  const handleGoogleLogin = async ({credential}) => {
    try {
      setLoginError(null);
      setGoogleLoginLoading(true);
      const { data: { user } } = await api.post('/oauth/google/log_in', {
        token: credential
      });
      localStorage.clear();

      if (user.email) {
        const profile = {
          $email: user.email
        };

        if (user.name) {
          const nameMatch = splitName(user.name);
          const firstName = nameMatch[1];
          const lastName = nameMatch[2];
          if (firstName) profile.$first_name = firstName;
          if (lastName) profile.$last_name = lastName;
        }

        mixpanel.people.set(profile);
        mixpanel.identify(user.email);
        const firstOrg = user.organisations && (user.organisations.find(o => (o.contractType === 'monthly' || o.contractType === 'fixed')) || user.organisations.find(o => o.contractType === 'trial'));

        mixpanel.track('Login', {
          product: user.organisations?.[0]?.signUpProduct === 'builder' ? 'builder' : 'analytics',
          oAuth: 'google',
          ...firstOrg && {
            'First Organisation Name': firstOrg.name,
            'First Organisation Uuid': firstOrg.uuid,
            'First Organisation Contract Type': firstOrg.contractType,
          },
        });
      }

      setUser(user);
    } catch (e) {
      const defaultError = 'Something went wrong. Please try again or <a href="mailto: support@zuko.io">get in touch</a>.';
      if (e.response?.status === 404 && e.response?.data?.errors?.[0].message.includes(`Couldn't find User`)) {
        setLoginError('Sorry, email not recognised. You can sign up for an account <a href="/signup">here</a>.');
      } else {
        setLoginError(defaultError);
      }
    } finally {
      setGoogleLoginLoading(false);
    }
  };


  const logUserInto = ({user}) => {
    const firstOrgSignUpProduct = user.organisations?.[0]?.signUpProduct;

    // Builder user loading root path, redirect to builder
    if (from?.path === '/' && firstOrgSignUpProduct === 'builder') return '/builder/forms';

    // Any other path attempted to access, redirect there
    if (from?.path) return `${from.path}${from.search ? from.search : ''}${from.hash ? from.hash : ''}`;

    // An endpoint attempted to load, redirect to the page
    if (redirectTo) return redirectTo;

    // Builder user, so direct to builder
    if (firstOrgSignUpProduct === 'builder') return '/builder/forms';

    return '/';
  };

  return (<>
    {currentUser &&
      <Redirect
        to={logUserInto({user: currentUser})}
      />}
    {(!currentUser && !storedUser) &&
      <Container fluid className="page login-background" id="login-page">
        <Helmet titleTemplate="%s | Zuko" defaultTitle="Zuko" defer={false}>
          <title>Login</title>
        </Helmet>
        <Col className="center-column justify-content-md-center">
          <Row className="g-0 login-logo justify-content-center">
            <div className="logo-wrapper">
              <div className="zuko-full-colour-logo" role="img" alt="Zuko logo"></div>
            </div>
          </Row>
          <Row className="g-0 login-title mb-3 justify-content-center">
            <h1>Login</h1>
          </Row>
          {loginError &&
            <Row className="alert-row g-0">
              <Alert dismissible variant="danger" closeVariant="white"
                onClose={() => setLoginError(null)}>
                <div className="alert-svg-icon my-auto"><VscWarning size="100%"/></div>
                <p className="alert-text m-0" dangerouslySetInnerHTML={{__html: loginError}}></p>
              </Alert>
            </Row>
          }
          {alert && alert.message && alert.type &&
            <Row className="alert-row g-0">
              <Alert dismissible variant={alert.type} {...(alert.type === 'danger' || alert.type === 'success') && {closeVariant: 'white'}}
                onClose={() => setAlert(null)}>
                <div className="alert-svg-icon my-auto">{alert.type === 'success' ? <VscCheck size="100%"/> : <VscWarning size="100%"/>}</div>
                {alert.linkIncluded ? <p className="alert-text m-0" dangerouslySetInnerHTML={{__html: alert.message}}></p> :
                  <p className="alert-text m-0">{alert.message}</p>}
              </Alert>
            </Row>
          }
          {location.pathname === '/integrations/shopify/login' &&
            <Row className="alert-row g-0 mt-3">
              <Alert variant="info" closeVariant="white">
                <div className="alert-svg-icon my-auto me-3"><VscInfo size="18px" className="mb-2" title="Please first login"/></div>
                <p className="alert-text m-0">Please login to continue connecting Zuko to your Shopify store.</p>
              </Alert>
            </Row>}
          {googleLoginLoading &&
          <Row className="g-0 d-flex justify-content-center text-center mt-3">
            <FaSpinner size="18px" className="spinning-icon" title="Logging in.."/>
          </Row>}
          {!googleLoginLoading && <>
            <Row className="g-0 justify-content-center" id="login-container">
              <Col className="col-auto p-0" id="login-container-col">
                <Form noValidate onSubmit={handleLogin} className="login-form">

                  {!mfaRequired && <>
                    <FormGroup className="form-group" controlId="email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control type="email" name="email" autoComplete="email" placeholder="you@example.com" value={email || ''}
                        className={((submitAttempted && !email) || (emailIsValid === false)) ? 'invalid-input' : emailIsValid ? 'is-valid' : ''}
                        onChange={({target: {value}}) => {
                          setEmail(value);
                          if (emailIsValid === false) {
                            setEmailIsValid(null);
                            setEmailErrorMsg(null);
                          }
                        }} required />
                      {emailErrorMsg && <Form.Control.Feedback type="invalid"className="feedback-row">{emailErrorMsg}</Form.Control.Feedback>}
                    </FormGroup>

                    <FormGroup className="form-group" controlId="password">
                      <Form.Label>Password</Form.Label>
                      <InputGroup>
                        <Form.Control type={passwordShown ? 'text' : 'password'} placeholder="Enter your password" name="password"  autoComplete="current-password" value={password || ''}
                          className={((submitAttempted && !password) || (passwordIsValid === false)) ? 'invalid-input' : passwordIsValid ? 'is-valid' : ''}
                          onChange={({target: {value}}) => {
                            setPassword(value);
                            if (passwordIsValid === false) {
                              setPasswordIsValid(null);
                              setPasswordErrorMsg(null);
                            }
                          }} required />
                        <InputGroup.Text className="password-show-icon-container" onClick={() => setPasswordShown(!passwordShown)}>
                          {passwordShown ? <AiOutlineEye size="20px" className="grey-icon" title="Show password"/> :
                            <AiOutlineEyeInvisible size="20px" className="grey-icon" title="Show password"/>}
                        </InputGroup.Text>
                      </InputGroup>
                      {passwordErrorMsg && <Form.Control.Feedback type="invalid"className="feedback-row">{passwordErrorMsg}</Form.Control.Feedback>}
                      <div className="input-link text-end"><Link to={{pathname: '/password-reset', state: {email}}}>Forgot password?</Link></div>
                    </FormGroup>
                  </>}

                  {mfaRequired &&
                <div className="text-center">
                  <p className="mfa-info">Two-factor authentication (2FA) is enabled for your account. Please use your authenticator app to view your verification code.</p>
                  <FormGroup controlId="mfa-token" className="form-group mt-2">
                    <Form.Label>Enter the 6-digit code:</Form.Label>
                    <Form.Control type="text"
                      inputMode="numeric"
                      value={otp || ''}
                      autoComplete="one-time-code"
                      className={`verification-code mx-auto text-center ${(otpIsValid === false) ? 'invalid-input' : ''}`}
                      maxLength={6}
                      minLength={6}
                      htmlSize={7}
                      onChange={({target: {value}}) => {
                        setOtp(value);
                        if (!otpIsValid) {
                          setOtpIsValid(null);
                          setOtpErrorMsg(null);
                        }
                      }} required autoFocus />
                    {otpErrorMsg && <Form.Control.Feedback type="invalid"className="feedback-row text-center" data-testid="mfa-token-invalid-feedback">{otpErrorMsg}</Form.Control.Feedback>}
                  </FormGroup>
                </div>}

                  <Row className="g-0 login-btn justify-content-center">
                    <Button variant="primary" className="submit-btn" type="submit"  onClick={() => setSubmitAttempted(true)} disabled={isSubmitting}>{mfaRequired ? 'Confirm' : 'Log in'}</Button>
                  </Row>

                  {mfaRequired &&
                  <Row className="g-0 mt-5 justify-content-center">
                    <p className="login-link text-center mb-0">Any problems? Contact <a href="mailto:support@zuko.io">Zuko support</a>.</p>
                  </Row>}
                </Form>
              </Col>
            </Row>
            <div className="oauth-container mx-auto">
              <div className="signup-divider text-center d-flex align-items-center">
                <hr className="mx-auto"/><p className="mb-0">Or</p><hr className="mx-auto"/>
              </div>
              <div className="d-flex justify-content-center google-button-container">
                <GoogleLogin
                  type="standard"
                  theme="outline"
                  size="large"
                  text="signin_with"
                  width="220px"
                  context="signin"
                  onSuccess={handleGoogleLogin}
                  onError={() => setLoginError('Sorry something went wrong. Please try again or login with email.')}
                />
              </div>
            </div>
          </>}
        </Col>
      </Container>}
  </>
  );
};

export default Login;
